// src/utils/userUtils.js
const adjectives = [
    'Swift', 'Clever', 'Bright', 'Quick', 'Eager',
    'Happy', 'Wise', 'Bold', 'Calm', 'Kind'
  ];
  
  const nouns = [
    'Panda', 'Fox', 'Eagle', 'Wolf', 'Bear',
    'Hawk', 'Lion', 'Tiger', 'Owl', 'Deer'
  ];
  
  export const generateAnonymousName = () => {
    const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const noun = nouns[Math.floor(Math.random() * nouns.length)];
    return `${adjective} ${noun}`;
  };
  
  export const userColors = [
    { bg: '#FF6B6B', text: '#FFFFFF' },
    { bg: '#4ECDC4', text: '#000000' },
    { bg: '#45B7D1', text: '#000000' },
    { bg: '#96CEB4', text: '#000000' },
    { bg: '#FFEEAD', text: '#000000' },
    { bg: '#D4A5A5', text: '#000000' },
    { bg: '#9B5DE5', text: '#FFFFFF' },
    { bg: '#F15BB5', text: '#FFFFFF' }
  ];
  
  export const getUserColor = (userId) => {
    const colors = [
      '#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4',
      '#FFEEAD', '#D4A5A5', '#9B5DE5', '#F15BB5'
    ];
  
    if (!userId || typeof userId !== 'string') {
      console.warn('Invalid userId passed to getUserColor:', userId);
      return { bg: colors[0], text: '#FFFFFF' }; // Default colors
    }
  
    const hash = userId.split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
  
    return {
      bg: colors[Math.abs(hash) % colors.length],
      text: '#FFFFFF' // Adjust text color for contrast
    };
};
  
  export const MAX_COLLABORATORS = 5;