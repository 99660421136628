// src/components/Layout/Navbar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { LoginButton } from '../Auth/LoginButton';
import { useAuth } from '../../hooks/useAuth';

const Navbar = () => {
  const { user, signOut } = useAuth();

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-gray-900/80 backdrop-blur-sm border-b border-gray-800/50">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between px-4 h-16">
          {/* Logo section */}
          <Link to="/" className="flex items-center gap-2.5 text-white">
            <img 
              src="/favicon.svg" 
              alt="RuntimePad Logo" 
              className="w-5 h-5"
            />
            <span className="text-lg font-semibold tracking-tight">RuntimePad</span>
          </Link>

          {/* Actions section */}
          <div className="flex items-center">
            {user ? (
              <div className="flex items-center gap-6">
                <Link 
                  to="/my-pads" 
                  className="text-sm font-medium text-gray-300 hover:text-white transition-colors"
                >
                  My Pads
                </Link>
                <button
                  onClick={signOut}
                  className="text-sm font-medium text-gray-300 hover:text-white transition-colors"
                >
                  Sign out
                </button>
              </div>
            ) : (
              <LoginButton />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;