// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './components/Auth/AuthProvider';
import { Footer } from './components/Layout/Footer';
import { Home } from './pages/Home';
import MyPads from './pages/MyPads';
import { PadEditor } from './pages/PadEditor';
import { FinalizeSignIn } from './pages/FinalizeSignIn';
import { NotFound } from './pages/NotFound';
import { Toaster } from 'react-hot-toast';
import Navbar from './components/Layout/Navbar';

// Wrapper component to conditionally render Navbar
const AppLayout = () => {
  const location = useLocation();
  const showNavbar = location.pathname !== '/';

  return (
    <div className="min-h-screen flex flex-col bg-gray-950">
      {showNavbar && <Navbar />}
      <main className={`flex-1 ${showNavbar ? 'pt-16' : ''}`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/my-pads" element={<MyPads />} />
          <Route path="/pad/:id" element={<PadEditor />} />
          <Route path="/finalize-signin" element={<FinalizeSignIn />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppLayout />
      </Router>
      <Toaster 
        position="top-right"
        toastOptions={{
          duration: 4000,
          style: {
            background: '#1f2937',
            color: '#fff',
          },
          success: {
            iconTheme: {
              primary: '#10B981',
              secondary: '#fff',
            },
          },
          error: {
            iconTheme: {
              primary: '#EF4444',
              secondary: '#fff',
            },
          },
        }}
      />
    </AuthProvider>
  );
};