// src/components/Editor/CollaborativeEditor.jsx
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Editor } from '@monaco-editor/react';

export const CollaborativeEditor = ({ 
  value, 
  language, 
  theme = 'vs-dark', 
  onChange, 
  socket, 
  userId, 
  userName, 
  userColor, 
  onLanguageChange, 
  maxCollaborators = 5, 
  collaborators = [],
  readOnly = false
}) => {
  const editorRef = useRef(null);
  const monacoRef = useRef(null);
  const decorationsRef = useRef(new Map());
  const ignoreChangeRef = useRef(false);
  const [activeUsers, setActiveUsers] = useState(new Map());
  const [cursorPositions, setCursorPositions] = useState(new Map());

  const getUserColor = useCallback((uid) => {
    const colors = [
      '#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4',
      '#FFEEAD', '#D4A5A5', '#9B5DE5', '#F15BB5'
    ];
    const hash = String(uid).split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return colors[Math.abs(hash) % colors.length];
  }, []);

  // Handle active users updates from WebSocket
  useEffect(() => {
    if (!socket) return;

    const handleActiveUsers = (users) => {
      const newActiveUsers = new Map();
      
      // Add current user
      newActiveUsers.set(userId, {
        name: userName || 'Anonymous',
        color: userColor?.bg || getUserColor(userId),
        isCurrentUser: true
      });

      // Add other users from the socket
      users.forEach(user => {
        if (user.id && user.id !== userId) {
          newActiveUsers.set(user.id, {
            name: user.name || 'Anonymous',
            color: user.color || getUserColor(user.id),
            isCurrentUser: false
          });
        }
      });

      setActiveUsers(newActiveUsers);
    };

    const handleUserJoined = (user) => {
      if (user.id && user.id !== userId) {
        setActiveUsers(prev => {
          const newUsers = new Map(prev);
          newUsers.set(user.id, {
            name: user.name || 'Anonymous',
            color: user.color || getUserColor(user.id),
            isCurrentUser: false
          });
          return newUsers;
        });
      }
    };

    socket.on('active-users', handleActiveUsers);
    socket.on('user-joined', handleUserJoined);

    return () => {
      socket.off('active-users', handleActiveUsers);
      socket.off('user-joined', handleUserJoined);
    };
  }, [socket, userId, userName, userColor, getUserColor]);

  const updateCursorStyles = useCallback(() => {
    let styleSheet = document.getElementById('cursor-styles');
    if (!styleSheet) {
      styleSheet = document.createElement('style');
      styleSheet.id = 'cursor-styles';
      document.head.appendChild(styleSheet);
    }

    const styles = Array.from(activeUsers.entries())
      .filter(([uid]) => uid !== userId) // Exclude current user
      .map(([uid, user]) => `
        .cursor-${uid} {
          background-color: ${user.color};
          width: 2px !important;
          margin-left: -1px;
          border-radius: 0;
          pointer-events: auto;
          z-index: 100;
        }
        .cursor-${uid}::before {
          content: "";
          position: absolute;
          top: 0;
          left: -3px;
          width: 8px;
          height: 8px;
          background-color: ${user.color};
          border-radius: 50%;
        }
        .cursor-${uid}::after {
          content: "${user.name}";
          position: absolute;
          top: -20px;
          left: 0;
          background-color: ${user.color};
          color: white;
          padding: 2px 6px;
          border-radius: 3px;
          font-size: 12px;
          white-space: nowrap;
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.15s ease;
          z-index: 101;
        }
        .cursor-${uid}:hover::after {
          opacity: 1;
        }
        .line-${uid} {
          background-color: ${user.color}15;
          z-index: 1;
        }
      `).join('\n');

    styleSheet.textContent = styles;
  }, [activeUsers, userId]);

  const updateDecorations = useCallback(() => {
    if (!editorRef.current || !monacoRef.current) return;

    // Get all current decorations to remove
    const currentDecorations = Array.from(decorationsRef.current.values()).flat();
    
    // Clear all current decorations
    if (currentDecorations.length > 0) {
      editorRef.current.deltaDecorations(currentDecorations, []);
    }
    
    // Reset decorations map
    decorationsRef.current.clear();

    // Create new decorations for each user
    Array.from(activeUsers.entries()).forEach(([uid, user]) => {
      if (uid === userId) return; // Skip current user

      const position = cursorPositions.get(uid);
      if (!position) return;

      const decorations = [{
        range: new monacoRef.current.Range(
          position.lineNumber,
          position.column,
          position.lineNumber,
          position.column + 1
        ),
        options: {
          className: `cursor-${uid}`,
          zIndex: 100
        }
      }, {
        range: new monacoRef.current.Range(
          position.lineNumber,
          1,
          position.lineNumber,
          1
        ),
        options: {
          isWholeLine: true,
          className: `line-${uid}`,
          zIndex: 1
        }
      }];

      const ids = editorRef.current.deltaDecorations([], decorations);
      decorationsRef.current.set(uid, ids);
    });
  }, [activeUsers, cursorPositions, userId]);

  useEffect(() => {
    updateCursorStyles();
    updateDecorations();
  }, [updateCursorStyles, updateDecorations]);

  const handleEditorDidMount = useCallback((editor, monaco) => {
    editorRef.current = editor;
    monacoRef.current = monaco;

    editor.onDidChangeCursorPosition(e => {
      if (socket && !ignoreChangeRef.current) {
        const position = {
          lineNumber: e.position.lineNumber,
          column: e.position.column
        };
        socket.emit('cursor-position', {
          position,
          userId,
          name: userName || 'Anonymous',
          color: userColor?.bg || getUserColor(userId)
        });
      }
    });
  }, [socket, userId, userName, userColor, getUserColor]);

  useEffect(() => {
    if (!socket) return;

    const handleCodeUpdate = ({ content, senderId }) => {
      if (senderId !== userId && editorRef.current) {
        ignoreChangeRef.current = true;
        const position = editorRef.current.getPosition();
        editorRef.current.setValue(content);
        editorRef.current.setPosition(position);
        ignoreChangeRef.current = false;
      }
    };

    const handleCursorUpdate = (data) => {
      const { userId: remoteUserId, position, name, color } = data;
      
      // Only update if the user is in our active users list or add them if they're not
      if (remoteUserId && remoteUserId !== userId) {
        setActiveUsers(prev => {
          const newUsers = new Map(prev);
          if (!newUsers.has(remoteUserId)) {
            newUsers.set(remoteUserId, {
              name: name || 'Anonymous',
              color: color || getUserColor(remoteUserId),
              isCurrentUser: false
            });
          }
          return newUsers;
        });
        
        setCursorPositions(prev => {
          const newPositions = new Map(prev);
          newPositions.set(remoteUserId, position);
          return newPositions;
        });
      }
    };

    const handleUserLeft = ({ userId: leftUserId }) => {
      setActiveUsers(prev => {
        const newUsers = new Map(prev);
        newUsers.delete(leftUserId);
        return newUsers;
      });
      
      setCursorPositions(prev => {
        const newPositions = new Map(prev);
        newPositions.delete(leftUserId);
        return newPositions;
      });

      // Clean up decorations
      if (decorationsRef.current.has(leftUserId)) {
        editorRef.current?.deltaDecorations(decorationsRef.current.get(leftUserId), []);
        decorationsRef.current.delete(leftUserId);
      }
    };

    socket.on('code-update', handleCodeUpdate);
    socket.on('cursor-position', handleCursorUpdate);
    socket.on('user-left', handleUserLeft);

    return () => {
      socket.off('code-update', handleCodeUpdate);
      socket.off('cursor-position', handleCursorUpdate);
      socket.off('user-left', handleUserLeft);
      
      const styleSheet = document.getElementById('cursor-styles');
      if (styleSheet) {
        styleSheet.remove();
      }
    };
  }, [socket, userId, getUserColor]);

  const handleChange = useCallback((newValue) => {
    if (ignoreChangeRef.current || readOnly) return;
    onChange?.(newValue);
    if (socket) {
      socket.emit('code-change', { 
        content: newValue, 
        senderId: userId 
      });
    }
  }, [socket, userId, onChange, readOnly]);

  return (
    <div className="h-full relative">
      {readOnly && (
        <div className="absolute inset-0 bg-gray-900/5 pointer-events-none z-10">
          <div className="absolute top-4 right-4 px-3 py-1.5 bg-gray-900/90 rounded-lg 
            text-gray-200 text-sm border border-gray-700/50">
            View Only Mode
          </div>
        </div>
      )}
      <Editor
        value={value}
        language={language}
        theme={theme}
        onChange={handleChange}
        onMount={handleEditorDidMount}
        options={{
    readOnly: readOnly,
    domReadOnly: readOnly,
    cursorStyle: readOnly ? 'block' : 'line',
    contextmenu: !readOnly,
    quickSuggestions: !readOnly,
    parameterHints: !readOnly,
    suggestOnTriggerCharacters: !readOnly,
    wordBasedSuggestions: !readOnly,
    minimap: { enabled: false },
    fontSize: 14,
    wordWrap: 'on',
    scrollBeyondLastLine: false,
    automaticLayout: true,
    renderLineHighlight: 'none',
    occurrencesHighlight: false,
    hideCursorInOverviewRuler: false,
    overviewRulerBorder: false,
    renderValidationDecorations: 'on',
    lineDecorationsWidth: 0,
    // Keep these features enabled even in read-only mode
    selectionHighlight: true,
    folding: true,
    mouseWheelZoom: true,
    renderWhitespace: 'none',
    links: true,
  }}
      />
    </div>
  );
};

export default CollaborativeEditor;