// src/components/Editor/EditorToolbar.jsx
import React from 'react';
import { Settings, Plus, Share2, Save } from 'lucide-react';
import { CollaboratorsList } from './CollaboratorsList';

const EditorToolbar = ({
  onNewPad,
  onSettingsToggle,
  onShare,
  onSave,
  connected,
  collaborators = [],
  maxCollaborators,
  isViewOnly
}) => {
  return (
    <div className="h-14 border-b border-gray-800 px-4 flex items-center justify-between bg-gray-900/80 backdrop-blur-sm">
      <div className="relative z-50">
        {/* Left side - Enhanced Collaborators List */}
        <CollaboratorsList 
          collaborators={collaborators} 
          maxCollaborators={maxCollaborators} 
        />
      </div>

      {/* Right side - Actions */}
      <div className="flex items-center gap-2">
        <button
          onClick={onShare}
          className="p-2 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded-lg transition-colors"
        >
          <Share2 className="w-5 h-5" />
        </button>

        {onSave && (
          <button
            onClick={onSave}
            disabled={!connected || isViewOnly}
            className="p-2 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded-lg transition-colors 
              disabled:opacity-50 disabled:hover:bg-transparent disabled:cursor-not-allowed"
          >
            <Save className="w-5 h-5" />
          </button>
        )}

        <div className="w-px h-4 bg-gray-800 mx-2"></div>

        <button
          onClick={onNewPad}
          className="p-2 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded-lg transition-colors"
        >
          <Plus className="w-5 h-5" />
        </button>

        <button
          onClick={onSettingsToggle}
          className="p-2 text-gray-400 hover:text-gray-300 hover:bg-gray-800 rounded-lg transition-colors"
        >
          <Settings className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default EditorToolbar;