// frontend/src/hooks/useWebSocket.js
import { useEffect, useRef, useState, useCallback } from 'react';
import { io } from 'socket.io-client';
import { useAuth } from './useAuth';
import { generateAnonymousName, getUserColor } from '../utils/userUtils';

export const useWebSocket = (padId) => {
  const { user } = useAuth();
  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(false);
  const socketRef = useRef(null);
  
  // Generate anonymous name and userId if not authenticated
  const [anonymousName] = useState(() => generateAnonymousName());
  const [userId] = useState(() => user?.uid || crypto.randomUUID());
  
  // Get user color based on userId
  const [userColor] = useState(() => getUserColor(user?.uid || userId));

  const connectSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    const socketInstance = io(process.env.REACT_APP_WS_URL, {
      path: '/socket.io',
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      query: { padId },
      auth: {
        token: user?.accessToken,
        userId: user?.uid || userId,
        email: user?.email,
        displayName: user?.displayName,
        anonymousName: user ? null : anonymousName,
        color: userColor.bg
      },
      transports: ['websocket']
    });
    console.log('WebSocket URL:', process.env.REACT_APP_WS_URL);

    socketInstance.on('connect', () => {
      console.log('WebSocket connected with userId:', userId);
      setConnected(true);
      
      // Send complete user info when joining pad
      socketInstance.emit('join-pad', {
        user: {
          userId: user?.uid || userId,
          email: user?.email,
          name: user?.email || anonymousName,
          displayName: user?.displayName,
          isAnonymous: !user,
          color: userColor.bg
        }
      });
    });

    socketInstance.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error);
      setConnected(false);
    });

    socketInstance.on('disconnect', () => {
      console.log('WebSocket disconnected');
      setConnected(false);
    });

    socketRef.current = socketInstance;
    setSocket(socketInstance);
  }, [padId, user, anonymousName, userId, userColor]);

  useEffect(() => {
    if (padId) {
      connectSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
        setSocket(null);
        setConnected(false);
      }
    };
  }, [padId, connectSocket]);

  return { 
    socket: socketRef.current, 
    connected,
    userId: user?.uid || userId,
    userName: user?.email || anonymousName,
    userColor
  };
};