// src/services/api.js
import axios from 'axios';
import { auth } from './firebase';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 10000
});

api.interceptors.request.use(async (config) => {
  try {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error) {
    console.error('API interceptor error:', error);
    return config;
  }
});

export const padsApi = {
  create: async (data) => {
    const response = await api.post('/pads', data);
    return response.data;
  },
  
  getById: async (id) => {
    const response = await api.get(`/pads/${id}`);
    return response.data;
  },
  
  getMyPads: async () => {
    const response = await api.get('/pads/my-pads');
    return response.data;
  },
  
  update: async (id, data) => {
    const response = await api.put(`/pads/${id}`, data);
    return response.data;
  },
  
  updateTitle: async (id, title) => {
    return padsApi.update(id, { title });
  },
  
  delete: async (id) => {
    const response = await api.delete(`/pads/${id}`);
    return response.data;
  },
  
  saveToPermanent: async (id) => {
    const response = await api.post(`/pads/${id}/save`);
    return response.data;
  },

  getCollaborators: async (id) => {
    const response = await api.get(`/pads/${id}/collaborators`);
    return response.data;
  },

  addCollaborator: async (id, email, accessLevel) => {
    const response = await api.post(`/pads/${id}/collaborators`, {
      email,
      accessLevel
    });
    return response.data;
  }
};