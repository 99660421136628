// runtimepad-frontend/src/pages/MyPads.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { padsApi } from '../services/api';
import { useAuth } from '../hooks/useAuth';
import { Code2, Plus, Clock, ExternalLink, Trash2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';

const MAX_PADS = 20;
const MAX_TITLE_LENGTH = 50;

export default function MyPads() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [pads, setPads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const titleInputRef = useRef(null);
  const [userPadCount, setUserPadCount] = useState(0);

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }
    loadPads();
  }, [user, navigate]);

  const loadPads = async () => {
    try {
      setLoading(true);
      const data = await padsApi.getMyPads();
      setPads(data);
      setUserPadCount(data.length);
    } catch (error) {
      console.error('Error loading pads:', error);
      toast.error('Failed to load pads');
    } finally {
      setLoading(false);
    }
  };

  // Inside MyPads component
  const debouncedTitleUpdate = useRef(
    debounce(async (id, newTitle) => {
      try {
        await padsApi.updateTitle(id, newTitle);
        toast.success('Title updated');
      } catch (error) {
        console.error('Error updating title:', error);
        toast.error('Failed to update title');
      }
    }, 1000)
  ).current;

  const handleTitleChange = (id, newTitle) => {
    // Limit title length
    if (newTitle.length > MAX_TITLE_LENGTH) return;

    // Update local state immediately
    setPads(pads.map(pad => 
      pad.id === id ? { ...pad, title: newTitle } : pad
    ));
    
    // Debounce API call and toast
    debouncedTitleUpdate(id, newTitle);
  };

  const handleTitleClick = (id) => {
    setEditingId(id);
    setTimeout(() => {
      titleInputRef.current?.focus();
      titleInputRef.current?.select();
    }, 0);
  };

  const handleNewPad = async () => {
    if (userPadCount >= MAX_PADS) {
      toast.error(`You can only create up to ${MAX_PADS} pads. Please delete some pads to create new ones.`);
      return;
    }

    try {
      const newPad = await padsApi.create({
        title: 'Untitled Pad',
        content: '// Start coding!',
        language: 'javascript'
      });
      navigate(`/pad/${newPad.id}`);
    } catch (error) {
      console.error('Error creating pad:', error);
      toast.error('Failed to create new pad');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this pad?')) {
      try {
        await padsApi.delete(id);
        await loadPads();
        toast.success('Pad deleted');
      } catch (error) {
        console.error('Error deleting pad:', error);
        toast.error('Failed to delete pad');
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-950 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-950 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex justify-between items-center mb-8">
      <div>
        <h1 className="text-2xl font-bold text-white mb-1">My Pads</h1>
        <p className="text-sm text-gray-400">
          {userPadCount} of {MAX_PADS} pads used
        </p>
      </div>
        <button
          onClick={handleNewPad}
          disabled={userPadCount >= MAX_PADS}
          className={`flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg transition-colors ${
            userPadCount >= MAX_PADS 
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-blue-700'
          }`}
        >
          <Plus className="w-5 h-5" />
        New Pad
      </button>
        </div>

        {pads.length === 0 ? (
          <div className="text-center py-16">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-800 mb-4">
              <Code2 className="w-8 h-8 text-blue-500" />
            </div>
            <p className="text-gray-400 mb-4">Create your first pad to start coding</p>
            <button
              onClick={handleNewPad}
              className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
            >
              Create Pad
            </button>
          </div>
        ) : (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {pads.map(pad => (
              <div 
                key={pad.id} 
                className="group bg-gray-900 rounded-xl border border-gray-800 p-6 hover:border-gray-700 transition-all"
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-start gap-4 min-w-0">
                    <div className="p-2 bg-gray-800 rounded-lg">
                      <Code2 className="w-5 h-5 text-blue-500" strokeWidth={2.5} />
                    </div>
                    <div className="min-w-0">
                      {/* Optionally, add character count */}
                      {editingId === pad.id && (
                        <div className="mt-1 text-xs text-gray-400">
                          {pad.title?.length || 0}/{MAX_TITLE_LENGTH} characters
                        </div>
                      )}
                      {editingId === pad.id ? (
                        <input
                        ref={titleInputRef}
                        type="text"
                        value={pad.title || ''}
                        maxLength={MAX_TITLE_LENGTH}
                        onChange={(e) => handleTitleChange(pad.id, e.target.value)}
                        onBlur={() => setEditingId(null)}
                        onKeyDown={(e) => e.key === 'Enter' && setEditingId(null)}
                        className="w-full bg-gray-800 text-white px-2 py-1 rounded border border-gray-700 focus:border-blue-500 focus:outline-none"
                        placeholder="Enter pad title..."
                      />
                      ) : (
                        <h2 
                          onClick={() => handleTitleClick(pad.id)}
                          className="text-lg font-medium text-white truncate cursor-pointer hover:text-gray-300"
                        >
                          {pad.title || 'Untitled Pad'}
                        </h2>
                      )}
                      <div className="mt-1 flex items-center gap-2 text-sm text-gray-400">
                        <Clock className="w-4 h-4" />
                        <span>Last updated {new Date(pad.updated_at).toLocaleString()}</span>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => handleDelete(pad.id)}
                    className="opacity-0 group-hover:opacity-100 p-2 text-gray-400 hover:text-red-400 transition-all"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>

                <div className="mt-4 flex items-center justify-between">
                  <span className="px-2.5 py-1 bg-gray-800 rounded text-sm text-gray-300">
                    {pad.language}
                  </span>
                  <Link
                    to={`/pad/${pad.id}`}
                    className="flex items-center gap-2 text-blue-500 hover:text-blue-400 transition-colors"
                  >
                    <span>Open Pad</span>
                    <ExternalLink className="w-4 h-4" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
