// src/components/Editor/CollaboratorsList.jsx
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Users } from 'lucide-react';
import { getUserColor } from '../../utils/userUtils';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

export const CollaboratorsList = ({ collaborators = [], maxCollaborators = 5 }) => {
  const activeCollaborators = collaborators.filter(c => !c.isViewOnly);
  const viewOnlyCollaborators = collaborators.filter(c => c.isViewOnly);
  const hasReachedLimit = activeCollaborators.length >= maxCollaborators;

  // Filter out invalid collaborators and ensure required properties exist
  const validCollaborators = collaborators.filter(collaborator =>
    collaborator && collaborator.id && collaborator.name
  );

  const renderTooltipContent = (collaborator) => (
    <div className="text-center">
      <div className="font-medium">
        {collaborator.name}
        {collaborator.isCurrentUser && " (You)"}
      </div>
      {collaborator.isViewOnly && (
        <div className="text-yellow-300 text-xs mt-0.5">View Only</div>
      )}
    </div>
  );

  return (
    <div className="flex items-center gap-3">
      {/* Users count indicator */}
      <div className="flex items-center gap-1.5 text-gray-400">
        <Users size={16} />
        <span className="text-sm font-medium">
          {activeCollaborators.length}/{maxCollaborators}
        </span>
      </div>

      {/* Collaborator avatars */}
      <div className="flex -space-x-2">
        <AnimatePresence>
          {validCollaborators.map(collaborator => {
            const initial = collaborator.name ? collaborator.name.charAt(0).toUpperCase() : '?';
            const userColor = collaborator.color || getUserColor(collaborator.id).bg;

            return (
              <Tippy
                key={collaborator.id}
                content={renderTooltipContent(collaborator)}
                animation="scale"
                arrow={true}
                placement="top"
                theme="dark"
                className="!text-white"
              >
                <motion.div
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div
                    className={`
                      w-8 h-8 rounded-full flex items-center justify-center 
                      text-sm font-medium text-white border-2 transition-all duration-200
                      ${collaborator.isViewOnly ? 'border-gray-600/50 opacity-75' : 'border-white'}
                      ${collaborator.isCurrentUser ? 'ring-2 ring-blue-500 ring-offset-1 ring-offset-gray-900' : ''}
                      hover:scale-105 cursor-help
                    `}
                    style={{ backgroundColor: userColor }}
                  >
                    {initial}
                  </div>
                </motion.div>
              </Tippy>
            );
          })}
        </AnimatePresence>
      </div>

      {/* Viewer count badge */}
      {hasReachedLimit && viewOnlyCollaborators.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="px-2 py-1 bg-yellow-500/10 border border-yellow-500/20 
            rounded-md text-yellow-200 text-xs font-medium"
        >
          +{viewOnlyCollaborators.length} viewer{viewOnlyCollaborators.length !== 1 ? 's' : ''}
        </motion.div>
      )}

      {/* Max editors indicator */}
      {hasReachedLimit && (
        <Tippy
          content={
            <div className="max-w-xs text-center">
              <div className="font-medium mb-1">Maximum Editors Limit</div>
              <div className="text-sm">
                This pad has reached the maximum of {maxCollaborators} active editors.
                New users will be in view-only mode until an active editor leaves.
              </div>
            </div>
          }
          animation="scale"
          arrow={true}
          placement="top"
          theme="dark"
          className="!text-white"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="px-2 py-1 bg-blue-500/10 border border-blue-500/20 
              rounded-md text-blue-200 text-xs font-medium cursor-help"
          >
            Max editors reached
          </motion.div>
        </Tippy>
      )}
    </div>
  );
};