// src/components/Editor/EditorSettings.jsx
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';

export const EditorSettings = ({
  isOpen,
  onClose,
  onThemeChange,
  onLanguageChange,
  onFontSizeChange,
  onWordWrapChange,
  onLineNumbersChange,
  currentLanguage = 'javascript',
  currentTheme = 'vs-dark',
  fontSize = 14,
  wordWrap = true,
  lineNumbers = true,
  disabled
}) => {
  const languages = [
    { id: 'javascript', name: 'JavaScript' },
    { id: 'typescript', name: 'TypeScript' },
    { id: 'python', name: 'Python' },
    { id: 'java', name: 'Java' },
    { id: 'csharp', name: 'C#' },
    { id: 'cpp', name: 'C++' },
    { id: 'go', name: 'Go' },
    { id: 'rust', name: 'Rust' },
    { id: 'ruby', name: 'Ruby' },
    { id: 'php', name: 'PHP' },
    { id: 'swift', name: 'Swift' },
    { id: 'kotlin', name: 'Kotlin' },
    { id: 'sql', name: 'SQL' },
    { id: 'html', name: 'HTML' },
    { id: 'css', name: 'CSS' },
    { id: 'xml', name: 'XML' },
    { id: 'yaml', name: 'YAML' },
    { id: 'json', name: 'JSON' },
    { id: 'markdown', name: 'Markdown' },
    { id: 'shell', name: 'Shell Script' }
  ];

  const themes = [
    { id: 'vs-dark', name: 'Dark Theme' },
    { id: 'light', name: 'Light Theme' },
    { id: 'hc-black', name: 'High Contrast Dark' },
    { id: 'hc-light', name: 'High Contrast Light' }
  ];

  const fontSizes = [12, 14, 16, 18, 20, 22, 24];

  const handleFontSizeChange = (size) => {
    onFontSizeChange?.(size);
  };
  
  const handleWordWrapChange = (enabled) => {
    onWordWrapChange?.(enabled);
  };
  
  const handleLineNumbersChange = (enabled) => {
    onLineNumbersChange?.(enabled);
  };
  

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, x: '100%' }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: '100%' }}
          transition={{ type: 'spring', damping: 20 }}
          className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex justify-end"
        >
          <div className="w-full max-w-md bg-gray-800 h-full overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-8">
                <div>
                  <h2 className="text-2xl font-bold text-white">Editor Settings</h2>
                  {disabled && (
                    <p className="text-yellow-400 text-sm mt-1">View-only mode: Some settings are disabled</p>
                  )}
                </div>
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-gray-700 rounded-full transition-colors"
                >
                  <X className="w-6 h-6 text-gray-400 hover:text-white" />
                </button>
              </div>

              <div className="space-y-8">
                {/* Language Selection - Collaborative Setting */}
                <div>
                  <div className="flex items-center mb-4">
                    <h3 className="text-lg font-semibold text-white">Programming Language</h3>
                    {disabled && (
                      <span className="ml-2 px-2 py-0.5 bg-yellow-400/10 text-yellow-400 text-xs rounded-full">
                        View Only
                      </span>
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    {languages.map(lang => (
                      <button
                        key={lang.id}
                        onClick={() => !disabled && onLanguageChange(lang.id)}
                        disabled={disabled}
                        className={`p-3 rounded-lg text-left transition-colors ${
                          currentLanguage === lang.id
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                        } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                      >
                        {lang.name}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Theme Selection - Personal Setting */}
                <div>
                  <h3 className="text-lg font-semibold text-white mb-4">Editor Theme</h3>
                  <div className="space-y-2">
                    {themes.map(theme => (
                      <button
                        key={theme.id}
                        onClick={() => onThemeChange(theme.id)}
                        className={`w-full p-3 rounded-lg text-left transition-colors ${
                          currentTheme === theme.id
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                        }`}
                      >
                        {theme.name}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Font Size - Personal Setting */}
                <div>
                  <h3 className="text-lg font-semibold text-white mb-4">Font Size</h3>
                  <select
                    value={fontSize}
                    onChange={(e) => handleFontSizeChange(Number(e.target.value))}
                    className="w-full bg-gray-700 text-white rounded-lg p-3"
                  >
                    {fontSizes.map(size => (
                      <option key={size} value={size}>
                        {size}px
                      </option>
                    ))}
                  </select>
                </div>

                {/* Editor Options - Personal Settings */}
                <div>
                  <h3 className="text-lg font-semibold text-white mb-4">Editor Options</h3>
                  <div className="space-y-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={wordWrap}
                        onChange={(e) => handleWordWrapChange(e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-600 rounded bg-gray-700 border-gray-600"
                      />
                      <span className="ml-2 text-white">Word Wrap</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={lineNumbers}
                        onChange={(e) => handleLineNumbersChange(e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-600 rounded bg-gray-700 border-gray-600"
                      />
                      <span className="ml-2 text-white">Line Numbers</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};