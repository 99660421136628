// src/pages/PadEditor.jsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CollaborativeEditor } from '../components/Editor/CollaborativeEditor';
import EditorToolbar from '../components/Editor/EditorToolbar';
import { EditorSettings } from '../components/Editor/EditorSettings';
import Navbar from '../components/Layout/Navbar';
import { useWebSocket } from '../hooks/useWebSocket';
import { padsApi } from '../services/api';
import { useAuth } from '../hooks/useAuth';
import { generateAnonymousName, getUserColor } from '../utils/userUtils';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';

const STORAGE_PREFIX = 'runtimepad_';
const MAX_COLLABORATORS = 5;

export const PadEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, signIn } = useAuth();
  const [pad, setPad] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState('vs-dark');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const activeUsersRef = useRef(new Set());
  const autoSaveTimerRef = useRef(null);
  const lastLanguageUpdateRef = useRef(null);

  const [editorSettings, setEditorSettings] = useState({
    fontSize: 14,
    wordWrap: true,
    lineNumbers: true,
    minimap: false,
    tabSize: 2,
    autoSave: true
  });

  // User identity management
  const [userId] = useState(() => {
    if (user?.uid) return user.uid;
    const stored = localStorage.getItem(`${STORAGE_PREFIX}userId`);
    if (stored) return stored;
    const newId = crypto.randomUUID();
    localStorage.setItem(`${STORAGE_PREFIX}userId`, newId);
    return newId;
  });

  const [userName] = useState(() => {
    if (user?.email) return user.email;
    const stored = localStorage.getItem(`${STORAGE_PREFIX}userName_${userId}`);
    if (stored) return stored;
    const newName = generateAnonymousName();
    localStorage.setItem(`${STORAGE_PREFIX}userName_${userId}`, newName);
    return newName;
  });

  const [userColor] = useState(() => getUserColor(userId));
  const { socket, connected } = useWebSocket(id);

  // Auto-save functionality
  const autoSave = useCallback(debounce(async (content, language) => {
    if (!user || !canEdit) return;
    try {
      await padsApi.update(id, { content, language });
    } catch (error) {
      console.error('Auto-save error:', error);
    }
  }, 2000), [user, canEdit, id]);

  useEffect(() => {
    return () => {
      autoSave.cancel();
      if (autoSaveTimerRef.current) {
        clearTimeout(autoSaveTimerRef.current);
      }
    };
  }, [autoSave]);

  // Load initial pad data
  const loadPad = useCallback(async () => {
    try {
      setLoading(true);
      const data = await padsApi.getById(id);
      setPad(data);

      // Set initial edit access based on ownership
      if (data.owner_id === userId) {
        activeUsersRef.current.add(userId);
        setCanEdit(true);
        if (socket && connected) {
          socket.emit('set-pad-creator', {
            userId,
            name: userName,
            color: userColor.bg
          });
        }
      }
    } catch (err) {
      console.error('Error loading pad:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [id, socket, connected, userId, userName, userColor.bg]);

  useEffect(() => {
    loadPad();
  }, [loadPad]);

  // WebSocket event handlers
  useEffect(() => {
    if (!socket || !pad) return;

    // Memoize current values for the cleanup function
    const currentSocket = socket;
    const currentPadId = id;

    const handleActiveUsers = (users) => {
      // Convert users array to proper format and deduplicate
      const uniqueUsers = Array.from(new Map(users.map(u => [
        u.id || u.userId,
        {
          ...u,
          id: u.id || u.userId,
          name: u.name || u.userName || 'Anonymous',
          color: u.color || getUserColor(u.id || u.userId).bg,
          isCurrentUser: (u.id || u.userId) === userId,
          canEdit: u.id === pad.owner_id || activeUsersRef.current.has(u.id || u.userId)
        }
      ])).values());

      setCollaborators(uniqueUsers);

      // Update edit permissions for current user if needed
      const isOwner = userId === pad.owner_id;
      if (!isOwner && activeUsersRef.current.size === 0) {
        const editingUsers = uniqueUsers.filter(u => !u.isViewOnly);
        if (editingUsers.length < MAX_COLLABORATORS) {
          activeUsersRef.current.add(userId);
          setCanEdit(true);
        }
      }
    };

    const handleLanguageChange = (data) => {
      const { language, userId: changerId } = data;
      
      // Prevent duplicate updates and infinite loops
      if (changerId !== userId && lastLanguageUpdateRef.current !== language) {
        lastLanguageUpdateRef.current = language;
        setPad(prev => ({ ...prev, language }));
      }
    };

    const handleUserJoined = (newUser) => {
      const uid = newUser.id || newUser.userId;
      
      if (collaborators.some(u => u.id === uid)) return;

      const isOwner = uid === pad.owner_id;
      let hasEditAccess = isOwner;

      if (!isOwner && activeUsersRef.current.size < MAX_COLLABORATORS) {
        activeUsersRef.current.add(uid);
        hasEditAccess = true;
      }

      if (uid === userId) {
        setCanEdit(hasEditAccess);
        if (hasEditAccess) {
          toast.success('You can now edit the pad');
        } else {
          toast.info(`View-only mode: Maximum editors (${MAX_COLLABORATORS}) reached`);
        }
      }

      setCollaborators(prev => [
        ...prev.filter(u => u.id !== uid),
        {
          id: uid,
          name: newUser.name || 'Anonymous',
          color: newUser.color || getUserColor(uid).bg,
          isCurrentUser: uid === userId,
          canEdit: hasEditAccess
        }
      ]);
    };

    const handleUserLeft = ({ userId: leftUserId, wasEditor }) => {
      if (activeUsersRef.current.has(leftUserId)) {
        activeUsersRef.current.delete(leftUserId);

        const remainingCollaborators = collaborators.filter(u => u.id !== leftUserId);
        const viewOnlyUser = remainingCollaborators.find(c => 
          !c.canEdit && !activeUsersRef.current.has(c.id)
        );

        if (viewOnlyUser && activeUsersRef.current.size < MAX_COLLABORATORS) {
          activeUsersRef.current.add(viewOnlyUser.id);
          
          if (viewOnlyUser.id === userId) {
            setCanEdit(true);
            toast.success('You can now edit the pad');
          }
        }

        setCollaborators(remainingCollaborators);
      } else {
        setCollaborators(prev => prev.filter(u => u.id !== leftUserId));
      }
    };

    const handleEditorPromoted = ({ userId: promotedId }) => {
      if (promotedId === userId) {
        setCanEdit(true);
        activeUsersRef.current.add(userId);
        toast.success('You can now edit the pad');
      }
    };

    // Set up socket event listeners
    socket.on('active-users', handleActiveUsers);
    socket.on('user-joined', handleUserJoined);
    socket.on('user-left', handleUserLeft);
    socket.on('editor-promoted', handleEditorPromoted);

    // Join the pad
    socket.emit('join-pad', {
      userId,
      userName,
      color: userColor.bg,
      isOwner: userId === pad.owner_id
    });

    return () => {
      socket.off('active-users', handleActiveUsers);
      socket.off('user-joined', handleUserJoined);
      socket.off('user-left', handleUserLeft);
      socket.off('editor-promoted', handleEditorPromoted);
      activeUsersRef.current.clear();
    };
  }, [socket, userId, userName, userColor, pad?.owner_id, collaborators]);

  // Handle content changes
  const handleContentChange = useCallback((newContent) => {
    if (!canEdit) return;
    
    setPad(prev => ({ ...prev, content: newContent }));
    
    socket?.emit('code-change', {
      content: newContent,
      padId: id,
      userId
    });
    
    if (editorSettings.autoSave) {
      autoSave(newContent, pad?.language);
    }
  }, [canEdit, socket, id, userId, editorSettings.autoSave, autoSave, pad?.language]);

  const handleLanguageChange = useCallback((newLanguage) => {
    if (!canEdit) {
      toast.error('You are in view-only mode');
      return;
    }

    setPad(prev => ({ ...prev, language: newLanguage }));
    socket?.emit('language-change', {
      language: newLanguage,
      padId: id,
      userId
    });
  }, [canEdit, socket, id, userId]);

  // Action handlers
  const handleSignIn = async () => {
    try {
      await signIn();
      toast.success('Successfully signed in!');
    } catch (error) {
      console.error('Sign in error:', error);
      toast.error('Failed to sign in');
    }
  };

  const handleNewPad = async () => {
    try {
      const newPad = await padsApi.create({
        title: 'Untitled Pad',
        content: '// Start coding!',
        language: 'javascript'
      });
      window.open(`/pad/${newPad.id}`, '_blank');
    } catch (error) {
      console.error('Create pad error:', error);
      toast.error('Failed to create new pad');
    }
  };

  const handleSave = async () => {
    if (!user) {
      toast.error('Please sign in to save the pad');
      return;
    }

    if (!canEdit) {
      toast.error('You are in view-only mode');
      return;
    }

    try {
      await padsApi.update(id, {
        content: pad.content,
        language: pad.language
      });
      toast.success('Pad saved successfully!');
    } catch (error) {
      console.error('Save error:', error);
      toast.error('Failed to save pad');
    }
  };

  const handleShare = async () => {
    try {
      const padUrl = `${window.location.origin}/pad/${id}`;
      await navigator.clipboard.writeText(padUrl);
      toast.success('Pad URL copied to clipboard!');
    } catch (error) {
      console.error('Share error:', error);
      toast.error('Failed to copy URL');
    }
  };

  const handleSettingsChange = (key, value) => {
    setEditorSettings(prev => ({ ...prev, [key]: value }));
  };

  // Loading and error states
  if (loading) {
    return (
      <div className="min-h-screen bg-gray-950 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || !pad) {
    return (
      <div className="min-h-screen bg-gray-950 flex items-center justify-center">
        <div className="text-center text-gray-300">
          <h2 className="text-xl font-bold mb-4">
            {error ? 'Error' : 'Pad Not Found'}
          </h2>
          {error && <p className="mb-4">{error}</p>}
          <button 
            onClick={() => navigate('/')}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Go Home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col bg-gray-950">
      <Navbar 
        isAnonymous={!user}
        onSignIn={handleSignIn}
        collaborators={collaborators}
        maxCollaborators={MAX_COLLABORATORS}
      />

      {!user && (
        <div className="bg-yellow-100/10 backdrop-blur-sm border-b border-yellow-200/20 text-yellow-200/90 px-4 py-2 text-sm">
          This pad will be automatically deleted after 24 hours. Sign in to save permanently.
        </div>
      )}

      {!canEdit && (
        <div className="bg-blue-500/10 backdrop-blur-sm border-b border-blue-500/20 text-blue-200/90 px-4 py-2 text-sm">
          View-only mode: Maximum number of editors ({MAX_COLLABORATORS}) reached. You can edit when an active editor leaves.
        </div>
      )}

      <EditorToolbar
        onNewPad={handleNewPad}
        onSettingsToggle={() => setIsSettingsOpen(!isSettingsOpen)}
        onShare={handleShare}
        onSave={canEdit ? handleSave : undefined}
        connected={connected}
        collaborators={collaborators}
        maxCollaborators={MAX_COLLABORATORS}
        isViewOnly={!canEdit}
      />
      
      <EditorSettings
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        onThemeChange={setTheme}
        onLanguageChange={handleLanguageChange}
        onFontSizeChange={size => handleSettingsChange('fontSize', size)}
        onWordWrapChange={enabled => handleSettingsChange('wordWrap', enabled)}
        onLineNumbersChange={enabled => handleSettingsChange('lineNumbers', enabled)}
        currentLanguage={pad.language}
        currentTheme={theme}
        fontSize={editorSettings.fontSize}
        wordWrap={editorSettings.wordWrap}
        lineNumbers={editorSettings.lineNumbers}
        disabled={!canEdit}
      />

      <div className="flex-1">
        <CollaborativeEditor
          value={pad.content}
          language={pad.language}
          theme={theme}
          readOnly={!canEdit}
          onChange={handleContentChange}
          socket={socket}
          userId={userId}
          userName={userName}
          userColor={userColor}
          maxCollaborators={MAX_COLLABORATORS}
          onLanguageChange={handleLanguageChange}
          collaborators={collaborators}
          options={{
            fontSize: editorSettings.fontSize,
            wordWrap: editorSettings.wordWrap ? 'on' : 'off',
            lineNumbers: editorSettings.lineNumbers ? 'on' : 'off',
            minimap: { enabled: editorSettings.minimap },
            tabSize: editorSettings.tabSize,
            contextmenu: true,
            scrollBeyondLastLine: false,
            automaticLayout: true
          }}
        />
      </div>
    </div>
  );
};

export default PadEditor;