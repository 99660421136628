// src/pages/Home.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Code, Share2, Users, ShieldCheck } from 'lucide-react';
import { padsApi } from '../services/api';

export const Home = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const createNewPad = async () => {
    try {
      const pad = await padsApi.create({
        title: 'Untitled Pad',
        content: '// Start coding!',
        language: 'javascript'
      });
      navigate(`/pad/${pad.id}`);
    } catch (error) {
      console.error('Error creating pad:', error);
    }
  };

  const features = [
    {
      icon: <Code className="w-8 h-8" />,
      title: "Intelligent Code Editor",
      description: "Smart completions and syntax highlighting"
    },
    {
      icon: <Share2 className="w-8 h-8" />,
      title: "Instant Sharing",
      description: "Share code with a simple link"
    },
    {
      icon: <Users className="w-8 h-8" />,
      title: "Real-time Collaboration",
      description: "Code together seamlessly"
    },
    {
      icon: <ShieldCheck className="w-8 h-8" />,
      title: "Responsible AI Assistance",
      description: "Ethical and secure AI-powered features"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      {/* Hero section */}
      <div className="relative pt-32 pb-20 px-4 sm:px-6 lg:px-8 lg:pt-40 lg:pb-28">
        <div className="relative max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <motion.h1
              className="text-4xl sm:text-6xl lg:text-7xl font-extrabold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500"
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              Welcome to RuntimePad
            </motion.h1>

            <motion.p
              className="mt-3 max-w-md mx-auto text-lg sm:text-xl text-gray-300"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              Collaborate on code in real-time with ease and confidence.
              Built-in tools to help you code better, together.
            </motion.p>

            <motion.div
              className="mt-8 flex justify-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <motion.button
                onClick={createNewPad}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Start Coding Now
              </motion.button>
            </motion.div>
          </motion.div>
        </div>
      </div>

      {/* Features section */}
      <div className="py-12 sm:py-16 lg:py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center mb-12">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              Why Choose RuntimePad?
            </h2>
            <p className="mt-3 text-xl text-gray-300 sm:mt-4">
              RuntimePad offers a seamless coding experience with powerful features.
            </p>
          </div>
          <motion.div
            className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="pt-6"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <div className="flow-root bg-gray-800 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 rounded-md shadow-lg bg-gradient-to-r from-blue-500 to-purple-500">
                        {feature.icon}
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-white tracking-tight">{feature.title}</h3>
                    <p className="mt-5 text-base text-gray-400">{feature.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>

      {/* Responsible AI section */}
      <div className="bg-gradient-to-b from-gray-800 to-gray-900 py-12 sm:py-16 lg:py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              Responsible AI Assistance
            </h2>
            <p className="mt-3 text-xl text-gray-300 sm:mt-4">
              RuntimePad offers AI-powered features that focus on enhancing code quality, security, and collaboration. We prioritize transparency and put you in control.
            </p>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="bg-gray-900 py-12 sm:py-16 lg:py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl mb-8">
              Ready to Collaborate on Code?
            </h2>
            <div>
              <motion.button
                onClick={createNewPad}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Try RuntimePad Now
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};