// src/components/Auth/LoginButton.jsx
import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';

export const LoginButton = () => {
  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState('idle');
  const { signIn, error } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');
    try {
      await signIn(email);
      setStatus('success');
      setTimeout(() => {
        setIsModalOpen(false);
        setStatus('idle');
      }, 2000);
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="px-3 py-1.5 bg-blue-500 hover:bg-blue-600 text-white text-sm rounded-lg transition-colors"
      >
        Sign in to save
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-96">
            <h2 className="text-xl font-bold text-white mb-4">Sign In</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded mb-4"
              />
              <button
                type="submit"
                disabled={status === 'loading'}
                className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 disabled:opacity-50"
              >
                {status === 'loading' ? 'Sending...' : 
                 status === 'success' ? 'Check your email!' : 
                 'Send Login Link'}
              </button>
            </form>
            {error && (
              <div className="mt-4 text-red-500 text-sm">
                {error}
              </div>
            )}
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 text-gray-400 hover:text-gray-300"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};
